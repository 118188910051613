var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "fill-height align-start",
      class: _vm.$config("settings.layoutColors.containerBackground")
    },
    [
      [
        _c("app-header", {
          on: {
            toggleNav: function($event) {
              _vm.drawer = !_vm.drawer
            }
          }
        }),
        _c("app-sidebar", {
          attrs: { drawer: _vm.drawer },
          on: {
            "update:drawer": function($event) {
              _vm.drawer = $event
            }
          }
        }),
        _c(
          "v-main",
          [
            _c(
              "v-container",
              { attrs: { fluid: "" } },
              [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c(
                          "transition",
                          { attrs: { name: "slide" } },
                          [_c("router-view")],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c("app-footer"),
        _c("psi-snackbar")
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }